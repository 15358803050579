import React from "react"
import { graphql, StaticQuery } from "gatsby"

// Pages
import LayoutV2 from "../components/v2/layout"
import SEO from "../components/seo"
import Images from "../components/image-links/image-links"
import "../utils/normalize.css"
import "../utils/css/v2.css"

const DistributedTeams = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const ImageGroupDiv = () => {
    return (
      <div>
        <section className="imageGroup">
          <div className="image-container">
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image3"]}
                  alt="arrow"
                  className="img-group-style"
                  style={{ width: "100%" }}
                ></img>
              </div>
            </div>
          </div>
          <div className="image-container">
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image2"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
            </div>
          </div>
          <div className="image-container">
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image6"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
            </div>
          </div>
        </section>
        <section className="imageGroup2">
          <div className="image-container" style={{ gridColumn: "unset" }}>
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image7"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
            </div>
          </div>
          <div className="image-container">
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image4"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
              <br />
              <div className="image--style">
                <img
                  src={Images["image1"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
            </div>
          </div>
          <div className="image-container">
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image5"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  return (
    <LayoutV2 location={location} title={siteTitle} headerActive={`Expertise`}>
      <SEO title={`Distributed Teams`} keywords={[]} />
      <div className="animated fadeIn scroller-adjustment">
        <ImageGroupDiv></ImageGroupDiv>
      </div>
    </LayoutV2>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <DistributedTeams location={props.location} data={data} {...props} />
    )}
  />
)
